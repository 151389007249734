!(function () {
    function getAsdClickUuidByValue() {
        var appendingIdentifier = "asd-";
        var uuidLength = 36;
        var searchString = window.location.search;
        var match = searchString.match(appendingIdentifier);
        if (match) {
            var startOfUuid = match.index + appendingIdentifier.length;
            var endOfUuid = startOfUuid + uuidLength;

            return searchString.slice(startOfUuid, endOfUuid);
        }
    }

    function getAsdClickUuid() {
        var paramName = "asdclickuuid";
        var searchString = window.location.search;
        var foundEmbeddedClickParam = getAsdClickUuidByValue()
        if (foundEmbeddedClickParam) {
            return foundEmbeddedClickParam;
        }

        var regex = new RegExp('[\\?&]' + paramName + '=([^?&#]*)', 'i');
        var results = regex.exec(searchString);

        return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    (function setAsdClickUuid() {
        var asdClickUuid = getAsdClickUuid();
        return asdClickUuid ? sessionStorage.setItem('asdclickuuid', asdClickUuid) : null;
    })();
})();
